@tailwind base;
@tailwind components;
@tailwind utilities;

/* Outfit-Complete */

@font-face {
  font-family: 'Outfit-Complete';
  src: local('Outfit-Complete'), url('./Resources/Fonts/Outfit-Complete/Outfit-ExtraLight.otf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Outfit-Complete';
  src: local('Outfit-Complete'), url('./Resources/Fonts/Outfit-Complete/Outfit-Light.otf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Outfit-Complete';
  src: local('Outfit-Complete'), url('./Resources/Fonts/Outfit-Complete/Outfit-Regular.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Outfit-Complete';
  src: local('Outfit-Complete'), url('./Resources/Fonts/Outfit-Complete/Outfit-Medium.otf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Outfit-Complete';
  src: local('Outfit-Complete'), url('./Resources/Fonts/Outfit-Complete/Outfit-SemiBold.otf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Outfit-Complete';
  src: local('Outfit-Complete'), url('./Resources/Fonts/Outfit-Complete/Outfit-Bold.otf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Outfit-Complete';
  src: local('Outfit-Complete'), url('./Resources/Fonts/Outfit-Complete/Outfit-ExtraBold.otf') format('truetype');
  font-weight: 800;
}

/* Grandstander */

@font-face {
  font-family: 'Grandstander';
  src: local('Grandstander'), url('./Resources/Fonts/Grandstander/Grandstander-ExtraLight.otf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Grandstander';
  src: local('Grandstander'), url('./Resources/Fonts/Grandstander/Grandstander-Light.otf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Grandstander';
  src: local('Grandstander'), url('./Resources/Fonts/Grandstander/Grandstander-Regular.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Grandstander';
  src: local('Grandstander'), url('./Resources/Fonts/Grandstander/Grandstander-Medium.otf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Grandstander';
  src: local('Grandstander'), url('./Resources/Fonts/Grandstander/Grandstander-SemiBold.otf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Grandstander';
  src: local('Grandstander'), url('./Resources/Fonts/Grandstander/Grandstander-Bold.otf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Grandstander';
  src: local('Grandstander'), url('./Resources/Fonts/Grandstander/Grandstander-ExtraBold.otf') format('truetype');
  font-weight: 800;
}

@layer base {
  html {
    font-family: 'Outfit-Complete', sans-serif;
    height: -webkit-fill-available;
  }

  body {
    @apply bg-primary text-primary;
    height: -webkit-fill-available;
  }

  h1 {
    @apply text-title;
  }

  h2 {
    @apply text-subtitle;
  }

  a {
    @apply text-url;
  }

  #root {
    height: -webkit-fill-available;
  }
}

@layer utilities {
  .v-stack {
    @apply flex flex-col;
  }

  .h-stack {
    @apply flex flex-row;
  }

  .text-secondary {
    @apply text-neutral-400 dark:text-neutral-500;
  }

  .font-fun {
    font-family: 'Grandstander', sans-serif;
  }

  /* Backgrounds */

  .bg-primary {
    @apply bg-gray-50 dark:bg-neutral-900;
  }

  .bg-secondary {
    @apply bg-gray-100 dark:bg-neutral-800;
  }

  .bg-tertiary {
    @apply bg-gray-200 dark:bg-neutral-700;
  }

  .bg-control {
    @apply dark:bg-gray-50 bg-slate-800;
  }

  /* Text styles */

  .text-title {
    @apply text-3xl font-medium lg:text-4xl;
  }

  .text-subtitle {
    @apply text-xl font-medium lg:text-2xl;
  }

  .text-body {
    @apply text-base font-normal lg:text-lg;
  }

  /* Text colors */

  .text-primary {
    @apply dark:text-neutral-50 text-slate-800;
  }

  .text-secondary {
    @apply dark:text-neutral-600 text-gray-400;
  }

  .text-tertiary {
    @apply dark:text-neutral-700 text-gray-400;
  }

  .text-url {
    @apply underline;
  }
}
